<template>
  <div class="ddj-offer">
    <!-- <div class="main-Title bgff"><h2>查询记录</h2></div> -->
    <div class="mainBox">
      <el-card shadow="hover" class="list-filter mb10">
        <el-form :model="filter" label-width="80px" size="small">
          <el-row>
            <el-col :xs="24" :sm="12" :md="6" :xl="4">
              <el-form-item label="Country:">
                <el-select v-model="filter.country" placeholder="Please select" class="w100">
                  <el-option label="All" value="" />
                  <el-option
                    v-for="item in options.country"
                    :key="item.countryCode"
                    :value="item.countryCode"
                    :label="item.countryCode"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :xl="4">
              <el-form-item label="OS:" label-width="50px">
                <el-select v-model="filter.os" placeholder="Please select OS" class="mr10">
                  <el-option label="全部" value="" />
                  <el-option
                    v-for="item in options.os"
                    :key="item.value"
                    :value="item.value"
                    :label="item.label"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :xl="2">
              <el-form-item label-width="0px">
                <el-button
                  type="primary"
                  size="mini"
                  @click="getList('filter')"
                  :loading="loading.list"
                  >Search</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          :data="list"
          v-loading="loading.list"
          ref="listTableRef"
          class="w100"
          size="mini"
          border
          highlight-current-row
        >
          <el-table-column label="查询id" prop="id"></el-table-column>
          <el-table-column label="Country" prop="country"></el-table-column>
          <el-table-column label="OS" prop="os"></el-table-column>
          <el-table-column label="标签" prop="tags"></el-table-column>
          <el-table-column label="状态" prop="status"></el-table-column>
          <el-table-column label="count" prop="count"></el-table-column>
          <el-table-column label="创建时间" prop="createTime"></el-table-column>
          <el-table-column label="更新时间" prop="updateTime"></el-table-column>
          <el-table-column label="操作" width="130" fixed="right" align="center">
            <template slot-scope="scope">
              <!--           <el-tooltip class="item" effect="dark" content="查看数据" placement="top">
                <el-button
                  type="primary"
                  icon="el-icon-view"
                  circle
                  size="mini"
                  @click="viewData(scope.row)"
                ></el-button>
              </el-tooltip> -->
              <el-button
                type="danger"
                icon="el-icon-delete"
                circle
                size="mini"
                @click="del(scope.row.id)"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <div v-if="isShowPage" class="pagination-container pt-10" align="center">
          <el-pagination
            :page-size="pages.pageSize"
            :total="pagesTotal"
            background
            layout="total, prev, pager, next, jumper"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
  import ddjSearchListCon from '../../../controllers/DDJ/v3/data/searchList';
  export default {
    name: 'ddjSearchList',
    ...ddjSearchListCon,
  };
</script>

<style></style>

import optionData from '@/assets/js/optionData';
import { fetchDataSearchPage, fetchDataSearchDel } from '@/api/DDJ/v3/data';
import { fetchCountryList } from '@/api/DDJ/v3/country';
export default {
  components: {},
  data() {
    return {
      filter: {},
      options: {
        country: [],
        os: optionData.osOption,
        advertiser: [
          {
            label: 'formoreWorth',
            value: 'formoreWorth',
          },
        ],
      },
      list: [],
      diallog: {
        updata: false,
      },
      loading: {
        list: false,
      },
      isUpdata: {
        id: '5466sd',
        subAff: '',
      },
      random: '',
      rules: {},
      pages: {
        pageNum: 1,
        pageSize: 10,
      },
      pagesTotal: 0,
      isShowPage: false,
    };
  },
  mounted() {
    this.getCountry();
    this.getList();
  },
  computed: {},
  methods: {
    getList(types) {
      if (types == 'filter') {
        this.pages.pageNum = 1;
        this.isShowPage = false;
      }
      let query = Object.assign(this.filter, this.pages);
      this.loading.list = true;
      fetchDataSearchPage(query).then((res) => {
        let data = res.result;
        this.list = [];
        this.list = data.records;
        this.pagesTotal = data.total;
        this.loading.list = false;
        this.isShowPage = true;
      });
    },
    getCountry() {
      fetchCountryList().then((res) => {
        this.options.country = res.result;
      });
    },
    viewData(row) {
      this.$router.push({
        path: '/click/data/search/view/v3',
        query: {
          id: row.id,
          country: row.country,
          os: row.os,
        },
      });
    },
    del(id) {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        customClass: 'maxWidthx100',
      })
        .then(() => {
          console.log(id);
          fetchDataSearchDel({ id: id }).then((res) => {
            if (res.code == 200) {
              this.getList();
              this.$message({
                type: 'success',
                message: res.message,
              });
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    // 分页监听
    handleCurrentChange(val) {
      this.pages.pageNum = val;
      this.getList();
    },
  },
};
